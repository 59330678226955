<template>
    <div class="h-full">
        <div v-if="loading">  <LoadingPage /> </div>
        <div class="bg-gray-100">
    <header class="bg-white py-6">
        <div class="container mx-auto px-4">
            <h1 class="text-3xl font-bold text-gray-800">Qui sommes-nous ?</h1>
        </div>
    </header>
    <main class="container mx-auto py-8 px-4">
        <section class="mb-8">
            <h2 class="text-2xl font-bold text-gray-800 text-black mb-4">Racines Vivantes<span class="text-green-600">(RaViv)</span>a fait le constat que</h2>
            <p class="text-gray-700">

*L’école est un lieu d’instruction sur l’histoire et les sciences... dans une langue étrangère aux élèves et aux enseignants, avec des référents culturels souvent étrangers. <br>

* Les parents, les acteurs de l’éducation et les élèves, vivant en Afrique ou ailleurs, souhaitent avoir une solution simple, motivante, amusante, moderne… pour rester en contact avec leur culture et les cultures des autres. <br>

*L’apprentissage des pratiques liées à la culture locale pourrait améliorer l’éducation et améliorer les niveaux d’instruction. <br>

Les solutions de Racines Vivantes <span class="text-green-600">(RaViv)</span>
<p class="font-bold text-black">Vision</p>
    <ul>
        <li>
            Démocratiser l’éducation aux valeurs culturelles locales.
        </li>
        <li>
            Offrir des solutions complémentaires aux programmes scolaires courants.
        </li>
        <li>
            RaViv, un Complément éducatif
        </li>
    </ul>


<span class="font-bold text-black">Objectifs:</span> 
<ul>
    <li>
        Contribuer à l’édification d’une société plus instruite et équitable
    </li>
    <li>
        Grâce aux Cultures locales
    </li>
    <li>
        A travers la Technologie
    </li>
</ul>



<h4 class="font-bold  text-bold">Ce que nous proposons:</h4>

<ul>
    <li>
        Valeurs : Révéler les valeurs prônées par la culture locale, comme la racine de toute éducation
    </li>
    <li>
        Education : Contribuer à donner plus de sens à l’école contemporaine 
    </li>
    <li>
        Démocratiser la culture : Favoriser l’accès de tous à sa culture et à la culture des autres, pour partager les valeurs
    </li>
</ul>

 

</p>
        </section>
        <section>
            <h2 class="text-2xl font-bold text-gray-800 mb-4">Notre équipe</h2>
            <div class="grid md:grid-cols-3 gap-4 grid-cols-1">
                <div class="bg-white rounded-lg shadow-lg p-4">
                    <!-- <img src="../assets/bilgo.png" alt="Photo de l'équipe" class="mb-4 h-50 w-50"> -->
                    <h3 class="text-lg font-bold text-gray-800">Jean-Claude BILGO</h3>
                    <p class="text-gray-700">Fondateur</p>
                </div>
                <div class="bg-white rounded-lg shadow-lg p-4">
                    <!-- <img src="../assets/yacou2.jpg" alt="Photo de l'équipe" class="mb-4"> -->
                    <h3 class="text-lg font-bold text-gray-800">yacouba BAMOGO</h3>
                    <p class="text-gray-700">Développeurs IT</p>
                </div>
                <div class="bg-white rounded-lg shadow-lg p-4">
                    <!-- <img src="../assets/issa.jpeg" alt="Photo de l'équipe" class="mb-4"> -->
                    <h3 class="text-lg font-bold text-gray-800">issa  SAWADOGO</h3>
                    <p class="text-gray-700">Développeurs IT</p>
                </div>
            </div>
        </section>
    </main>
</div>
    </div>



</template>

<script setup>
import {ref,onMounted} from 'vue'
import LoadingPage from '../components/LoadingPage.vue';
let loading = ref(true);

onMounted(() => {
  showLoading();
  })

function showLoading(){
  loading.value == true;
  setTimeout(() => {
    loading.value = false;
  }, 2000);
}
const people = [
  {
    name: 'Leslie Alexander',
    role: 'Co-Founder / CEO',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: 'Leslie Alexander',
    role: 'Co-Founder / CEO',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  // More people...
]
</script>
